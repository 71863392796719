import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withPage } from "../PageContainer";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import * as Widget from "../Components/Widget";
import * as Icon from "../Components/Icon";
import EditRecordForm from "../Components/EditRecordForm";

class CreateRecordPage extends React.Component {
  render() {
    let { appActions, navActions, profile, extraCss } = this.props;

    return (
      <CreateRecordPage.Wrapper>
        <CreateRecordPage.BgPrimary />
        <CreateRecordPage.BgGrey />

        <CreateRecordPage.Content>
          <div
            className="back-button"
            onClick={() => navActions.push("/profile")}
          >
            <Icon.Close color="black" size={26} />
          </div>

          <h2>創建新紀錄</h2>
          {profile ? (
            <div>
              <Widget.Center extraCss="margin-bottom: 20px;">
                <EditRecordForm />
              </Widget.Center>
            </div>
          ) : (
            <Widget.Center>
              <Widget.Spinner />
            </Widget.Center>
          )}
        </CreateRecordPage.Content>
      </CreateRecordPage.Wrapper>
    );
  }

  static Wrapper = styled.div``;

  static Content = styled.div`
    margin: 20px auto;
    transform: translateY(30px);
    max-width: 600px;
    min-height: 66vh;
    padding: 20px;
    z-index: 1;
    background-color: white;
    position: relative;
    border-radius: 6px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

    & .back-button {
      position: absolute;
      right: 0;
      top: 0;
      padding: 10px;
      cursor: pointer;
    }

    & > h2 {
      text-align: center;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 800px) {
      width: 300px;
    }
  `;

  static BgPrimary = styled.div`
    position: fixed;
    background-color: teal;
    height: 50vh;
    width: 100vw;
    z-index: 0;
    top: 0;
    left: 0;
  `;

  static BgGrey = styled.div`
    position: fixed;
    background-color: #eee;
    height: 50vh;
    width: 100vw;
    z-index: 0;
    top: 50vh;
    left: 0;
  `;
}

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state)
    }),
    ActionCreator
  )(CreateRecordPage)
);
