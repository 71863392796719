import React, { Component } from "react";
import styled from "styled-components";
import moment from "moment";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import * as Widget from "./Widget";

class EditRecordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: props.defaultData ? props.defaultData.height : "",
      weight: props.defaultData ? props.defaultData.weight : "",
      date: props.defaultData
        ? props.defaultData.date
        : moment().format("YYYY-MM-DD"),
      error: null,
      submitting: false,
      ...this._getStateFromProps(this.props) // gender
    };
  }

  _getStateFromProps = props => {
    let profile = props.profile;
    if (profile) {
      return {
        gender:
          (profile.male !== null && (profile.male ? "male" : "female")) || ""
      };
    }

    return {
      gender: ""
    };
  };

  render() {
    let { profile } = this.props;
    let { gender, height, weight, date, error, submitting } = this.state;

    return (
      <EditRecordForm.Wrapper>
        <EditRecordForm.FormField>
          <label>性別</label>
          {(() => {
            if (profile.male === true) {
              return (
                <select value={gender} disabled>
                  <option value="male">男</option>
                </select>
              );
            }

            if (profile.male === false) {
              return (
                <select value={gender} disabled>
                  <option value="female">女</option>
                </select>
              );
            }

            return (
              <select
                value={gender}
                onChange={e =>
                  this.setState({ gender: e.target.value, error: "" })
                }
              >
                <option value="">尚未設定</option>
                <option value="male">男</option>
                <option value="female">女</option>
              </select>
            );
          })()}
        </EditRecordForm.FormField>

        <EditRecordForm.FormField>
          <label>身高(cm)</label>
          <input
            value={height}
            type="number"
            onChange={e => this.setState({ height: e.target.value, error: "" })}
          />
        </EditRecordForm.FormField>

        <EditRecordForm.FormField>
          <label>體重(kg)</label>
          <input
            value={weight}
            type="number"
            onChange={e => this.setState({ weight: e.target.value, error: "" })}
          />
        </EditRecordForm.FormField>

        <EditRecordForm.FormField>
          <label>日期</label>
          <input
            value={date}
            type="date"
            onChange={e => this.setState({ date: e.target.value, error: "" })}
          />
        </EditRecordForm.FormField>

        {error && <EditRecordForm.ErrorMsg>{error}</EditRecordForm.ErrorMsg>}

        {submitting ? (
          <Widget.Center extraCss="margin-top: 10px;">
            <Widget.Spinner />
          </Widget.Center>
        ) : (
          <Widget.NativeButton
            onClick={this._submit}
            color="teal"
            extraCss="display: block; width: 260px; margin: 0 auto; margin-top: 30px;"
          >
            確認送出
          </Widget.NativeButton>
        )}
      </EditRecordForm.Wrapper>
    );
  }

  _delay = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  _submit = () => {
    let { gender, height, weight, date } = this.state;
    let { profile } = this.props;

    weight = parseFloat(weight);
    height = parseFloat(height);

    if (
      weight <= 0 ||
      height <= 0 ||
      !gender ||
      isNaN(weight) ||
      isNaN(height)
    ) {
      this.setState({ error: "輸入資料不正確!" });
      return;
    } else if (moment(date).diff(moment(profile.birthday), "years") > 18) {
      this.setState({ error: "創建記錄年齡超過18歲，請輸入18歲以內的紀錄!" });
      return;
    }

    let { appActions, navActions, defaultData } = this.props;
    let male = gender === "male" ? true : false;

    this.setState({ submitting: true });

    if (defaultData) {
      this._delay(900)
        .then(() =>
          appActions.editHealthRecord({
            id: defaultData.id,
            male,
            height,
            weight,
            date
          })
        )
        .then(record => {
          this.setState({ submitting: false });
          appActions.updateProfile();
          navActions.push(`/record?id=${record.id}`);
        })
        .catch(err => {
          console.warn(err);
          this.setState({ submitting: false, error: "Submit fail!!" });
        });
      return;
    }

    this._delay(900)
      .then(() =>
        appActions.postHealthRecord({
          male,
          height,
          weight,
          date
        })
      )
      .then(record => {
        this.setState({ submitting: false });
        appActions.updateProfile();
        navActions.push(`/record?id=${record.id}`);
      })
      .catch(err => {
        console.warn(err);
        this.setState({ submitting: false, error: "Submit fail!!" });
      });
  };

  static Wrapper = styled.div`
    padding: 10px;
  `;

  static FormField = styled.div`
    margin-bottom: 20px;
    display: flex;
    width: 260px;

    & > label {
      margin-right: 10px;
      flex: 1;
      font-weight: 700;
    }

    & > input {
      border: none;
      border-bottom: 1px solid #ccc;
      text-align: right;
      width: 125px;
    }

    & > input:focus {
      outline: none;
    }

    & > select {
      width: 100px;
      text-align-last: right;
    }

    & > select > option {
      direction: rtl;
    }
  `;

  static ErrorMsg = styled.div`
    color: red;
  `;
}

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state)
  }),
  ActionCreator
)(EditRecordForm);
